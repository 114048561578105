import type { GID, UnlikelyProduct } from "@unlikelystudio/commerce-connector"

import type { InferReturn } from "~/@types/generics"
import { productMetafields } from "~/lib/shopify/constants"
import { getSfProducts } from "~/lib/shopify/queries/get-sf-products"
import { getSfProductsByIds } from "~/lib/shopify/queries/get-sf-products-by-id"
import { getLocalizedMetafields } from "~/lib/shopify/utils/get-localized-metafields"
import { sfTagsArrayToQuery } from "~/lib/shopify/utils/sf-tags-array-to-query"
import { serializeProductHeaderSP } from "~/components/ui/ProductHeader/_data/serializer"
import { unlikelyProductToTrackingData } from "~/providers/GTMTrackingProvider/utils/unlikely-product-to-tracking-data"
import { objectValues } from "~/utils/object-values"

type Params = { locale: string } & (
  | { gids: GID[]; refs?: never; id?: never }
  | { refs: string[]; gids?: never; id: GID }
)

export async function serializeShopTheLook({ gids, refs, locale, id }: Params) {
  const { products } = gids
    ? await getSfProductsByIds({
        ids: gids,
        locale,
        nextTags: gids,
      })
    : await getSfProducts({
        locale: locale,
        metafieldKeys: objectValues(getLocalizedMetafields(productMetafields, locale)),
        query: sfTagsArrayToQuery(refs),
        mediaFirst: 10,
        collectionsFirst: 20,
        includeTags: true,
        nextTags: [id],
      })

  const sortedProducts = gids ? sortByGids(gids, products) : sortByRefs(refs, products)

  const serializedProducts =
    (await Promise.all(
      sortedProducts.map(async (product) => ({
        ...serializeProductHeaderSP(product, locale),
        trackingData: unlikelyProductToTrackingData({ product, locale, isShopTheLook: true }),
      }))
    )) ?? []

  return serializedProducts
}

export type ShopTheLook = InferReturn<typeof serializeShopTheLook>

function sortByGids(gids: GID[], products: UnlikelyProduct[]) {
  return gids.map((gid) => products.find((product) => product.id === gid)).filter(Boolean)
}

function sortByRefs(refs: string[], products: UnlikelyProduct[]) {
  return refs.map((ref) => products.find((product) => product.tags.includes(ref))).filter(Boolean)
}
