import { sfProducts } from "@unlikelystudio/commerce-connector"

import type { SetPartialKeys } from "~/@types/generics"
import { LOCATIONS_COUNT } from "~/lib/shopify/constants/locations"
import { sfFetchWrapper, type ClientOptions } from "~/lib/shopify/utils/sf-fetch-wrapper"

export type IGetShopifyProducts = Awaited<ReturnType<typeof getSfProducts>>

export type IGetShopifyProductsParams = SetPartialKeys<Parameters<typeof get>[0], "first">

export async function getSfProducts(input: IGetShopifyProductsParams, clientOptions?: ClientOptions) {
  return await get(
    {
      first: 20,
      imagesFirst: 10,
      includeDescriptionHtml: true,
      includeSelectedOptions: true,
      includeQuantityAvailable: true,
      variantsFirst: 100,
      includePrices: true,
      storeAvailabilityFirst: LOCATIONS_COUNT,
      ...input,
    },
    clientOptions
  )
}

const get = sfFetchWrapper(sfProducts)("product")
